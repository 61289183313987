exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-active-jobs-js": () => import("./../../../src/pages/active-jobs.js" /* webpackChunkName: "component---src-pages-active-jobs-js" */),
  "component---src-pages-agriculture-js": () => import("./../../../src/pages/agriculture.js" /* webpackChunkName: "component---src-pages-agriculture-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-medicine-js": () => import("./../../../src/pages/medicine.js" /* webpackChunkName: "component---src-pages-medicine-js" */),
  "component---src-pages-ongoing-projects-js": () => import("./../../../src/pages/ongoing-projects.js" /* webpackChunkName: "component---src-pages-ongoing-projects-js" */),
  "component---src-pages-qualified-workers-js": () => import("./../../../src/pages/qualified-workers.js" /* webpackChunkName: "component---src-pages-qualified-workers-js" */),
  "component---src-pages-working-fields-js": () => import("./../../../src/pages/working-fields.js" /* webpackChunkName: "component---src-pages-working-fields-js" */)
}

